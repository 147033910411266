import React from "react"
import classnames from "classnames"
import Img from "gatsby-image"

import styles from "./testimonial.module.scss"

const Testimonial = ({
  content,
  cite: { name, organisation, link, avatar },
  reverse,
}) => (
  <blockquote
    className={classnames(
      styles.Testimonial,
      reverse ? styles.TestimonialReverse : null
    )}
  >
    <div className={styles.content}>{content()}</div>
    {name && (
      <footer className={styles.footer}>
        <cite className={styles.cite}>
          {avatar && (
            <div
              className={styles.image}
              style={{
                width: avatar.childImageSharp.fluid.presentationWidth,
                height: "auto",
              }}
            >
              <Img
                fadeIn={false}
                fluid={{
                  ...avatar.childImageSharp.fluid,
                }}
                sizes={{
                  ...avatar.childImageSharp.fluid,
                  aspectRatio:
                    avatar.childImageSharp.fluid.presentationWidth /
                    avatar.childImageSharp.fluid.presentationHeight,
                }}
                alt={name}
              />
            </div>
          )}
          <div className={styles.name}>{name}</div>
          <div className={styles.link}>{organisation}</div>
        </cite>
      </footer>
    )}
  </blockquote>
)

Testimonial.defaultProps = {
  cite: {
    name: undefined,
    avatar: () => {},
  },
  reverse: false,
}

export default Testimonial
