import React from "react"
import Img from "gatsby-image"

import styles from "./iconList.module.scss"

const IconList = ({ items }) => {
  if (items.length > 0) {
    return (
      <ul className={styles.iconList}>
        {items.map((item, index) => (
          <li className={styles.item} key={index}>
            <span className={styles.icon}>
              <Img
                fadeIn={false}
                fixed={item.image}
                alt={item.alt ? item.alt : ""}
              />
            </span>
            {item.text}
          </li>
        ))}
      </ul>
    )
  }

  return null
}

export default IconList
