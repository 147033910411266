import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import styles from "./about.module.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageContainer from "../components/pageContainer"
import Container from "../components/container"
import Box from "../components/box"
import IconList from "../components/iconList"
import Testimonial from "../components/testimonial"
import ButtonSet from "../components/buttonSet"

const AboutPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={`About ${data.site.siteMetadata.title}`}
        noSuffix
        description={data.site.siteMetadata.description}
      />
      <PageContainer>
        <Container>
          <div className="header align-center">
            <h1>About Ian Lunn</h1>
          </div>
          <div
            style={{
              float: "left",
              marginRight: "1em",
            }}
          >
            <Img
              fadeIn={false}
              fixed={data.avatar.childImageSharp.fixed}
              alt="Photo of Ian Lunn"
              style={{
                display: "block",
              }}
            />
          </div>
          <p
            dangerouslySetInnerHTML={{
              __html: data.site.siteMetadata.profile.bio,
            }}
          />

          <p>
            I work to make a better web; one that is fast, easy to use,
            beautiful, accessible to all, and frustration-free. Regardless of
            your specific business requirements, in solving these challenges,
            you have a great chance of finding success online.
          </p>

          <p>
            My main experience is in front-end development but I also have a
            passion for design. Given that producing a modern website requires
            the combination of design, server technology, and the layer that
            users interacts with, I believe having experience in both design and
            development allows for making the most optimal user experiences.
          </p>

          {/* <p>
            Does your designer know how to produce UI with performance and
            accessibility concerns in mind? Can your developer match your
            designer's passion and eye for detail? Are your designer and
            developer effectively communicating the nuances of their respective
            fields?
          </p>

          <p>
            Producing a modern website requires the combination of design,
            server technology, and the layer that users interacts with. A
            website must both please the eye and feel great to use to achieve
            the most for your business. That's why I believe the best user
            experiences are created by those that are experienced in these
            multiple fields. The added benefit of a hybrid designer/developer is
            that they can work with the full scope of a project in mind;
            simplifying the process of working amongst a team and producing a
            more cohesive experience for your users.
          </p> */}
          <p>
            <Link to="/testimonials/">My clients</Link> have found that hiring
            me has saved them time and money, made it easier to grow their
            website alongside their business, and made for a product that is
            consistently of high quality.
          </p>

          <Testimonial
            reverse
            content={() => (
              <span
                dangerouslySetInnerHTML={{
                  __html: data.testimonialAdamHouston.edges[0].node.html,
                }}
              />
            )}
            cite={data.testimonialAdamHouston.edges[0].node.frontmatter}
          />

          <p>
            I take great pride in not only being able to complete work to the
            high standard you expect but also go the extra mile wherever
            possible. I enjoy getting to know a business, its goals, and how I
            can best apply myself to make sure your website succeeds. For
            established clients, I particularly like analysing their metrics and
            making data-based decisions and recommendations, leading to better
            rates of success.
          </p>
          <p>
            I can quickly and efficiently join your team using
            continuous-integration methods and tools such as Git and Agile.
          </p>

          <h2 id="front-end-developer">Front-end Developer</h2>
          <p>
            I use HTML, CSS, and JavaScript to produce responsive websites and
            web apps that provide users the best and most appropriate experience
            suited to their device and browser.
          </p>

          <div className={styles.box}>
            <Box>
              <h4 style={{ paddingLeft: "1em" }}>Skills</h4>
              <IconList
                items={[
                  {
                    text: "JavaScript (vanilla, React, etc)",
                    image: data.jsLogo.childImageSharp.fixed,
                    alt: "JavaScript logo",
                  },
                  {
                    text: "Semantic HTML",
                    image: data.htmlLogo.childImageSharp.fixed,
                    alt: "HTML logo",
                  },
                  {
                    text: "Responsive CSS",
                    image: data.cssLogo.childImageSharp.fixed,
                    alt: "CSS logo",
                  },
                  {
                    text: "Performance",
                    image: data.lighthouseLogo.childImageSharp.fixed,
                    alt: "Google Lighthouse logo",
                  },
                  {
                    text: "Animation",
                    image: data.animationLogo.childImageSharp.fixed,
                  },
                  {
                    text: "Accessibility & Best Practices",
                    image: data.axeLogo.childImageSharp.fixed,
                    alt: "Axe by Deque logo",
                  },
                  {
                    text: "SEO",
                    image: data.googleLogo.childImageSharp.fixed,
                    alt: "Google logo",
                  },
                ]}
              />
            </Box>
            <p className={styles.boxSmall}>
              {" "}
              <small>
                <em>
                  This is a list of my foundational front-end development
                  skills. I'm also familiar with many tools and frameworks that
                  fall under these main categories. If you're curious as to
                  whether I can use X, Y or Z,{" "}
                  <Link to="/contact">just ask</Link>!{" "}
                  <a href="#philosophy">
                    See my philosophy on using web technology
                  </a>
                  .
                </em>
              </small>
            </p>
          </div>

          <p>
            I write standards based code that is semantic, accessible,
            search-engine friendly, easy to maintain, cross browser compatible,
            and performant.
          </p>

          <p>
            Building a future-proof and easy-to-maintain website is achieved by
            breaking down a web page into its smallest pieces, often called
            components, and building them in a way that exists outside the
            context of the design they first appear in. This allows for
            components that can be re-used in other areas of a website as it
            grows and makes the intention easier for other developers to
            understand and test. Wherever possible, I find it valuable to create
            a style guide (using{" "}
            <a
              href="https://storybook.js.org/"
              rel="nofollow noreferrer noopener"
            >
              Storybook
            </a>{" "}
            for example) that acts as a document for a reusable and maintainable
            code base. All involved in a project can rely on a style guide to
            understand a project’s language and produce a more consistent user
            experience. Re-usable components make for a website that can quickly
            grow as does your business.
          </p>

          <p>
            I am also experienced in building systems that implement business
            logic and interact with server-side technology via APIs and tools
            such as React and Redux, as well as testing code using tools such as
            Jasmine.
          </p>

          <p>
            I have a special interest in use of animation on the web. I
            particularly like working on websites that have seamless transitions
            and require high-level performance to maintain a smooth and
            appealing website.
          </p>

          <Testimonial
            reverse
            content={() => (
              <span
                dangerouslySetInnerHTML={{
                  __html: data.testimonialGregColby.edges[0].node.html,
                }}
              />
            )}
            cite={data.testimonialGregColby.edges[0].node.frontmatter}
          />

          <h2 id="design">Design & Prototyping</h2>

          <div className={styles.box}>
            <Box>
              <h4 style={{ paddingLeft: "1em" }}>Skills</h4>
              <IconList
                items={[
                  {
                    text: "Sketch",
                    image: data.sketchLogo.childImageSharp.fixed,
                    alt: "Sketch logo",
                  },
                  {
                    text: "Photoshop",
                    image: data.photoshopLogo.childImageSharp.fixed,
                    alt: "Photoshop logo",
                  },
                  {
                    text: "Illustrator",
                    image: data.illustratorLogo.childImageSharp.fixed,
                    alt: "Illustrator logo",
                  },
                  {
                    text: "Zeplin",
                    image: data.zeplinLogo.childImageSharp.fixed,
                    alt: "Zeplin logo",
                  },
                  {
                    text: "Invision",
                    image: data.invisionLogo.childImageSharp.fixed,
                    alt: "Invision logo",
                  },
                ]}
              />
            </Box>
          </div>

          <p>
            I believe it is important to understand both design and development
            to achieve a successful user-facing product. By involving a
            front-end developer at the design & prototyping phase, technical
            challenges can be solved early, producing a more cohesive product in
            the most time efficient manner.
          </p>

          <p>
            I am also a keen advocate of producing prototypes before fully
            coding. These prototypes are a hybrid of design and interaction that
            enable a team to experience how a user interface will feel before it
            is coded. This gives an opportunity for all involved to provide
            feedback at an early stage and be a part of the iteration process.
          </p>
          <h2 id="wordpress-developer">WordPress Developer</h2>
          <p>
            In many projects, my clients require the ability to manage the
            content of their website. WordPress is the world’s most popular
            content management system which I have many years of experience
            with. My focus when using WordPress is on ease of use and
            maintainability; making sure you can manage your content as simply
            and quickly as possible.
          </p>
          <h2 id="philosophy">Philosophy</h2>
          <p>
            Web technology is vast and only getting bigger. There are a huge
            number of tools available and endless methods for using them,
            without there always being a clear and concise "go-to" approach.
          </p>
          <p>Some developers may:</p>
          <ul>
            <li>
              Put their needs and desires before the purpose of the project
              which can lead to slow websites and a poor return on investment
              for your business
            </li>
            <li>
              List the latest cutting edge tools as part of their skills but not
              have a solid understanding of foundational skills
            </li>
          </ul>
          <p>I believe in:</p>
          <ul>
            <li>
              Putting the user first. Users that have a good experience are more
              likely to complete the actions that lead to your business goals
              being met
            </li>
            <li>
              Maintain a deep understanding of foundational technology and rely
              on tools and frameworks where they will best serve your
              requirements -- not treat them as the be-all and end-all
            </li>
          </ul>
          <p>
            After understanding your requirements, I can use my years of
            experience to determine the right tools and development methods for
            you. Often the simplest approach is the most successful.
          </p>
          <p>
            The sustainability of the web development is important to me too. By
            ensuring we work in ways that are most efficient, we can reduce the
            footprint of what we produce on the web. This in many cases has a
            benefit to the user because if a website is efficient, it benefits
            from good performance and a quick user experience.
          </p>

          <Testimonial
            reverse
            content={() => (
              <span
                dangerouslySetInnerHTML={{
                  __html: data.testimonialRenaldoWebb.edges[0].node.html,
                }}
              />
            )}
            cite={data.testimonialRenaldoWebb.edges[0].node.frontmatter}
          />

          <h2>Get Started</h2>
          <p>
            If you’d like to find out more about how I work and my process of
            working with clients, please <Link to="/contact">get in touch</Link>{" "}
            and give a little information about the project you have in mind.
            I’ll send a Getting Started guide that answers many of the common
            questions I receive from potential clients. We can then set up a
            call to discuss how I can be of most value to your project.
          </p>
          <figure>
            <Box>
              <Link to="/contact" title="Contact Ian Lunn">
                <Img
                  fadeIn={false}
                  fluid={{
                    ...data.gettingStarted.childImageSharp.fluid,
                    aspectRatio: 1680 / 1188,
                  }}
                  alt="Screenshot from Getting Started Hiring Ian Lunn PDF"
                />
              </Link>
            </Box>
            <figcaption>
              The Getting Started guide I will send upon receiving your project
              request
            </figcaption>
          </figure>
          <p>
            I endeavour to respond to all enquiries within 1 working day. I look
            forward to learning about your project.
          </p>

          <ButtonSet
            render={buttonSetStyles => (
              <>
                <div className={buttonSetStyles.button}>
                  <Link to="/contact" className="button">
                    Get In Touch
                  </Link>
                </div>
                <div className={buttonSetStyles.link}>
                  <Link to="/testimonials">View testimonials</Link>
                </div>
              </>
            )}
          />
        </Container>
      </PageContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site: site {
      siteMetadata {
        title
        description
        profile {
          bio
          availability
        }
      }
    }

    avatar: file(relativePath: { eq: "avatar-small@2x.jpg" }) {
      childImageSharp {
        fixed(width: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    testimonialAdamHouston: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order] }
      filter: {
        fileAbsolutePath: {
          regex: "/(?!testimonials/_archive)(?!testimonials/_empty)(testimonials)/"
        }
        frontmatter: { name: { eq: "Adam Houston" } }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            name
            link
            organisation
            avatar {
              childImageSharp {
                fluid(maxWidth: 120) {
                  presentationWidth
                  presentationHeight
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    testimonialGregColby: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order] }
      filter: {
        fileAbsolutePath: {
          regex: "/(?!testimonials/_archive)(?!testimonials/_empty)(testimonials)/"
        }
        frontmatter: { name: { eq: "Greg Colby" } }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            name
            link
            organisation
            avatar {
              childImageSharp {
                fluid(maxWidth: 120) {
                  presentationWidth
                  presentationHeight
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    testimonialRenaldoWebb: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order] }
      filter: {
        fileAbsolutePath: {
          regex: "/(?!testimonials/_archive)(?!testimonials/_empty)(testimonials)/"
        }
        frontmatter: { name: { eq: "Renaldo Webb" } }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            name
            link
            organisation
            avatar {
              childImageSharp {
                fluid(maxWidth: 120) {
                  presentationWidth
                  presentationHeight
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    gettingStarted: file(relativePath: { eq: "getting-started-double.jpg" }) {
      childImageSharp {
        fluid(srcSetBreakpoints: [420, 840, 1680]) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    jsLogo: file(relativePath: { eq: "javascript-logo@2x.png" }) {
      childImageSharp {
        fixed(height: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    htmlLogo: file(relativePath: { eq: "html-logo@2x.png" }) {
      childImageSharp {
        fixed(height: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    cssLogo: file(relativePath: { eq: "css-logo@2x.png" }) {
      childImageSharp {
        fixed(height: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    lighthouseLogo: file(relativePath: { eq: "lighthouse-logo@2x.png" }) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    animationLogo: file(relativePath: { eq: "sequence-logo.png" }) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    axeLogo: file(relativePath: { eq: "axe-logo.jpg" }) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    googleLogo: file(relativePath: { eq: "google-logo@2x.png" }) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    sketchLogo: file(relativePath: { eq: "sketch-icon.png" }) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    photoshopLogo: file(relativePath: { eq: "photoshop-logo.png" }) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    illustratorLogo: file(relativePath: { eq: "illustrator-logo.jpg" }) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    zeplinLogo: file(relativePath: { eq: "zeplin-logo.png" }) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    invisionLogo: file(relativePath: { eq: "invision-logo.png" }) {
      childImageSharp {
        fixed(width: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

export default AboutPage
